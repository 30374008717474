import React from 'react';
import * as S from './styles';
import events from './eventsData';
import chat from '../../../assets/icons/icon-conversa.png'

const Updates = () => {
  return (
    <S.Container>
      <S.Header>
        <S.Title>Atualizações</S.Title>
        <S.ViewAll><img src={chat} /><h1>SOLICITAR PRODUTO</h1></S.ViewAll>
      </S.Header>

      {events.map((event) => (
        <S.EventCard key={event.id}>
          <S.EventInfo>
            <S.EventDate>{event.date}</S.EventDate>
            <S.EventTitle>{event.title}</S.EventTitle>
            <S.Mark>{event.mark}</S.Mark>
          </S.EventInfo>

          <S.FarmWrapper>
            <S.TypeToxic>{event.type}</S.TypeToxic>
            <S.Active>{event.active}</S.Active>
          </S.FarmWrapper>
  
        </S.EventCard>
      ))}

    </S.Container>
  );
};

export default Updates;
