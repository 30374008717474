import React from 'react';

import * as S from './styles';
import document from '../../../assets/icons/document.svg';

export const compendioG = "@compendioG";

const Recipe = ({ ...props }) => {


    return (
        <S.Container>
            <S.Header>
                <S.HeaderTitle>Emita seu primeiro Receituário agora mesmo</S.HeaderTitle>
                
                <S.ButtonHeader>
                    <img src={document} alt=""/>
                    EMITIR NOVA RECEITA
                </S.ButtonHeader>
            </S.Header>
        </S.Container>
    );
};

export default Recipe;