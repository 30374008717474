import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 307px;
    padding: 16px;
    max-width: 330px;
   
    gap: 16px;
    display: flex;
    flex-direction: column;
    
    border-radius: 16px;
    background-color: #F8F8F9; 

    >div:nth-of-type(1){
        height: 48px;

        margin: 0;
        display: flex;
        align-items: center;

        border-radius: 10rem;
    }

    >div:nth-of-type(2){
        border: none;
        background-color: transparent;

        >div:nth-of-type(1){
            border-radius: 0.5rem;

            >button:nth-of-type(1){
                :hover{
                    background-color: transparent;
                }
            }

            >button:nth-of-type(2){
                cursor: default;

                >span{
                    color: #21262F;
                    font-weight: 700;
                    font-size: 0.875rem; 
                    line-height: 1.3125rem;
                    font-family: "Lato", Sans-serif;

                    text-transform: capitalize;
                }

                :hover{
                    background-color: transparent;
                }
            }

            >button:nth-of-type(3){
                :hover{
                    background-color: transparent;
                }
            }
        }

        >div:nth-last-child(1){
            >div>div{
                >button{
                    padding: 1.05rem 0; 
                }
                
                >div{
                    >div:nth-of-type(1){
                        justify-content: center;

  

                        >div>abbr{
                            text-decoration: none;
                        }
                    }
                }

                >div>div:nth-of-type(2) {
                    justify-content: center;
                    margin-top: 0.5rem;
                    >button{
                        width: 2.5rem;
                        height: 1.625rem;

                        display: flex;
                        margin-bottom: 0.5rem;
                        align-items: center;
                        justify-content: center;
                    }
                    
                    .react-calendar__tile--active{
                        border-radius: 5rem;
                        color: #FFFFFF !important;
                        background-color: #21262F !important;
                    }
                    
                    .react-calendar__active--today{
                        border-radius: 5rem;
                        color: #589BFF !important;
                        background-color: transparent !important;
                    }
                    
                    .react-calendar__tile-select {
                        color: #FFFFFF; 
                        background-color: #21262F !important; 
                    }

                    .react-calendar-dateNow-focus {
                        color: #FFFFFF !important; 
                        border-radius: 5rem !important;
                        background-color: #589BFF !important; 
                    }

                    .react-calendar__tile:disabled {
                        display: none;
                    }

                    .react-calendar__month-view__days__day--weekend{
                        color: #21262F;
                    }

                    .react-calendar__tile.past-day {
                        color:#AAAAAA;
                        }
                    }
                    .react-calendar__tile:enabled:hover {
                        background-color: transparent ;
                    }
            }
        }

        >div>div>div>div{
            >div:nth-of-type(1) {
               >div{
                width: 42.5px;
                padding: 0 0.2rem;
                flex: none !important;

                @media (max-width: 375px) {
                    width: 36.5px;
                }
               }
            }

            >div:nth-of-type(2) {
                >button{
                    padding: 0 0.2rem;
                    flex: none !important;
                    width: 42.5px !important;

                    @media (max-width: 375px) {
                        width: 36.5px !important;
                    }
               }
            }
        }
    }

    @media (max-width: 768px) {
        align-items: center;
        width: 100% !important;
        max-width: 100% !important;
    }

`;

export const FarmWrapper = styled.div`
    width: auto;
    display: flex;
    padding: 0.5rem;

    margin-bottom: 1rem;
    
    border-radius: 1rem;
    justify-content: center;
    border: 1px solid black;

    @media (max-width: 768px) {
        width: 90%;
    }

    @media (max-width: 360px) {
        width: 280px;
    }
`;

export const Lyrics = styled.div`
    flex-direction: column;
`;

export const FarmName = styled.div`
    text-transform: uppercase;

    color: #333;
    font-weight: 800;
    font-size: 0.6rem;
    letter-spacing: 0.1rem;
`;

export const FarmSubtittle = styled.p`
    text-transform: uppercase;
 
    font-size: 0.5rem;
    text-align: center;
    letter-spacing: 0.1rem;
`;

export const FarmSelector = styled.button`
    all: unset;
    top: 0.1rem;
    left: 4.5rem;
    cursor: pointer;
    transition: 0.4s;
    position: relative;

    @media (max-width: 360px) {
        margin-right: 1rem;
    }
`;

export const Preview = styled.div`
    width: 100%;
    height: 144px;
    padding: 12px 0;

    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 8px;
    background-color:#FFFFFF;
`;

export const Preview1 = styled.div`
    width: auto;
    height: auto;

    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const Preview2 = styled(Preview1)`
   align-items: flex-start;
`;

export const MinTemperature = styled.div`
    width: auto;
    height: auto;

    gap: 8px;
    display: flex;
    align-items: center;

    >img{
        width: 16px;
        height: 16px;
    }

    >p{
        text-transform: uppercase;

        font-size: 10px;
        font-weight: 800;
        line-height: 15px;
        font-family: "Lato";
        letter-spacing: 0.1em;

        @media (max-width: 375px) {
            font-size: 8px;
            line-height: 15px;
        }
    }
`;

export const MaxTemperature = styled(MinTemperature)``;

export const ProbabilityRain = styled(MinTemperature)``;

export const PrecipitationRain = styled(MinTemperature)``;

export const Humidity = styled(MinTemperature)``;

export const Wind = styled(MinTemperature)``;