import React, { useState } from "react";
import * as S from './styles';

import ArrowDown from '../../../assets/icons/icon-arrow-down-nova-home.svg';
import graphics from '../../../assets/icons/graphics.png';
import document from '../../../assets/icons/document.svg';
import grafico from '../../../assets/grafico-areatratada-teste.png';

const TreatedArea = () => {    
    const [showGraphic, setShowGraphic] = useState(false);


    return (
        <S.Container className="treatedArea">
            <S.Header>
                <S.FarmWrapper>
                    <S.FarmName>ÁREA TRATADA (HA)</S.FarmName>
                    <S.FarmSelector>
                        <img src={ArrowDown} alt="Icone de flecha direcionada para baixo" />
                    </S.FarmSelector>
                </S.FarmWrapper>

                <S.Report>
                    <img src={graphics} alt="Ícone de gráficos" />
                    <h1>VER RELATÓRIOS</h1>
                </S.Report>
            </S.Header>

            <S.Body>
                {showGraphic ? (
                    <img src={grafico} alt="Gráfico da área tratada" />
                ) : (
                    <>
                        <h1>Emita seu primeiro Receituário para ver os relatórios</h1>
                        <S.ButtonHeader onClick={() => setShowGraphic(true)}>
                            <img src={document} alt="Ícone de documento" />
                            EMITIR NOVA RECEITA
                        </S.ButtonHeader>
                    </>
                )}
            </S.Body>
        </S.Container>
    );
};

export default TreatedArea;
