import styled from "styled-components";

export const Container = styled.div`
    width: auto;
    height: auto;

    display: flex;
    align-items: center;

    @media (max-width: 768px) {
            width: 100%;
            max-width: 100%;
        }
`;

export const Wrapper = styled.div` 
    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
    }
`;

export const CalendaryEvent = styled.div` 
    max-width: 20.625rem;
    height: 20.688rem;
    padding: 1rem 1.5rem;

    gap: 1rem;
    display: flex;
    flex-direction: column;

    border-radius: 1rem;
    background-color: #F8F8F9;

    >div:nth-last-child(1){
        border: none;
        background-color: transparent;

        >div:nth-of-type(1){
            border-radius: 0.5rem;
            background-color: #FFFFFF;

            >button:nth-of-type(1){
                background-color: #FFFFFF;

                :hover{
                    background-color: transparent;
                }
            }

            >button:nth-of-type(2){
                cursor: default;
                >span{
                    color: #21262F;
                    font-weight: 700;
                    font-size: 0.875rem; 
                    line-height: 1.3125rem;
                    font-family: "Lato", Sans-serif;

                    text-transform: capitalize;
                }

                :hover{
                    background-color: transparent;
                }
            }

            >button:nth-of-type(3){
                background-color: #FFFFFF;
                
                :hover{
                    background-color: transparent;
                }
            }
        }

        >div:nth-last-child(1){
            >div>div{
                >button{
                    padding: 1.05rem 0; 
                }
                
                >div{
                    >div:nth-of-type(1){
                        >div>abbr{
                            text-decoration: none;
                        }
                    }
                }

                >div>div:nth-of-type(2) {
                    >button{
                        width: 2.5rem;
                        height: 1.625rem;

                        display: flex;
                        margin-bottom: 0.5rem;
                        align-items: center;
                        justify-content: center;
                    }
                    
                    .react-calendar__tile--active{
                        border-radius: 5rem;
                        color: #FFFFFF !important;
                        background-color: #21262F !important;
                    }
                    
                    .react-calendar__active--today{
                        border-radius: 5rem;
                        color: #589BFF !important;
                        background-color: transparent !important;
                    }
                    
                    .react-calendar__tile-select {
                        color: #FFFFFF; 
                        background-color: #21262F !important; 
                    }

                    .react-calendar-dateNow-focus {
                        color: #FFFFFF !important; 
                        border-radius: 5rem !important;
                        background-color: #589BFF !important; 
                    }

                    .react-calendar__tile:disabled {
                        display: none;
                    }

                    .react-calendar__tile:enabled:hover {
                        background-color: transparent ;
                    }
                }
            }
        }        
    }

    @media (max-width: 768px) {
            width: 100%;
            max-width: 100%;
            align-items: center;
        }
`;

export const ScheduleAndNewEvent = styled.div` 
    width: 100%;
    height: auto;

    display: flex;
    justify-content: space-between;

    >p{
        color: #21262F;
        font-weight: 700;
        font-size: 1.125rem; 
        line-height: 1.75rem;
        font-family: "Lato", Sans-serif;
    }
`;

export const NewEvent = styled.button` 
    all: unset;
    width: auto;
    height: auto;

    gap: 0.5rem;
    display: flex;
    align-items: center;

    cursor: pointer;

    >img{
        width: 1rem;
        height: auto;
    }

    >p{
        cursor: pointer;

        color: #589BFF;
        font-weight: 800;
        font-size: 0.625rem; 
        line-height: 0.9375rem;
        letter-spacing: 0.1rem;
        font-family: "Lato", Sans-serif;
    }
`;
