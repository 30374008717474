import React from "react";
import * as S from './styles';

import GraphicCircle from "./GraphicCircle.js";
import ArrowDown from '../../../assets/icons/icon-arrow-down-nova-home.svg'
import Perfil from '../../../assets/img-perfil-nova-home.jpg'

export const compendioG = "@compendioG";

const CircleData = ({ ...props }) => {
    return (
        <S.Container className="circleData">
            <S.Wrapper>
                <S.ProfileCircle>
                    
                    <S.Profile>
                        <img src={Perfil} alt="Imagem de perfil" />
                        <p>(TESTE) CRISTINA GONÇALVES</p>
                        <S.ButtonProfile>
                            <img src={ArrowDown} alt="Icone de flecha direcionada para baixo" />
                        </S.ButtonProfile>
                    </S.Profile>

                </S.ProfileCircle>
                <S.CirclesContainer>

                    <S.CircleData>
                        <GraphicCircle value={15} />
                        <S.Subtitles>RECEITAS <br/> RESTANTES</S.Subtitles>
                    </S.CircleData>

                    <S.CircleTRT>
                        <GraphicCircle value={7} />
                        <S.Subtitles>TRT DISPONÍVEIS <br/> #01234567</S.Subtitles>
                    </S.CircleTRT>

                </S.CirclesContainer>

            </S.Wrapper>
        </S.Container>
    );
};

export default CircleData;
