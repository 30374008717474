import React, { useState } from 'react';
import * as S from './styles';

import IconPlusBlue from '../../../assets/icons/icon-plus-blue-nova-home.svg';
import IconArrowLeft from '../../../assets/icons/icon-arrow-left-nova-home.svg';
import IconArrowRight from '../../../assets/icons/icon-arrow-right-nova-home.svg';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export const compendioG = "@compendioG";

const CalendaryEvent = ({ ...props }) => {
    const [currentDate, setCurrentDate] = useState(new Date());

    const tileClassName = ({ date, view }) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
      
        const normalizedDate = new Date(date);
        normalizedDate.setHours(0, 0, 0, 0);
      
        const selectedDate = new Date(currentDate);
        selectedDate.setHours(0, 0, 0, 0);
      
        if (view === "month") {
      
          if (normalizedDate < today) {
            return "react-calendar__tile past-day";
          }
      
          if (normalizedDate.getTime() === today.getTime()) {
            if (normalizedDate.getTime() === selectedDate.getTime()) {
              return "react-calendar-dateNow-focus";
            }
            return "react-calendar__active--today";
          }
      
          if (normalizedDate.getTime() === selectedDate.getTime()) {
            return "react-calendar__tile-select";
          }
        }
      
        return null;
      };

    return (
        <S.Container>
            <S.Wrapper>
                <S.CalendaryEvent>
                    <S.ScheduleAndNewEvent>
                        <p>Minha agenda</p>

                        <S.NewEvent>
                            <img src={IconPlusBlue} alt="Ícone de mais em azul" />
                            <p>CRIAR EVENTO</p>
                        </S.NewEvent>
                    </S.ScheduleAndNewEvent>

                    <Calendar
                        onChange={setCurrentDate}
                        value={currentDate}
                        prevLabel={<img src={IconArrowLeft} alt="Seta para a esquerda" />}
                        nextLabel={<img src={IconArrowRight} alt="Seta para a direita" />}
                        prev2Label={null}
                        next2Label={null}
                        tileClassName={tileClassName}
                        calendarType="gregory"
                        navigationLabel={({ date }) =>
                            date
                                .toLocaleDateString('pt-BR', { month: 'long', year: 'numeric' })
                                .replace(' de ', ' ')
                        }
                        formatShortWeekday={(locale, date) =>
                            date
                                .toLocaleDateString('pt-BR', { weekday: 'short' })
                                .charAt(0)
                                .toUpperCase()
                        }
                    />
                </S.CalendaryEvent>
            </S.Wrapper>
        </S.Container>
    );
};

export default CalendaryEvent;
