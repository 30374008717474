import React from 'react';

import ArrowDown from '../../../assets/icons/icon-arrow-down-nova-home.svg'
import Notificação from '../../../assets/icons/icon-bell-nova-home.svg'
import Perfil from '../../../assets/img-perfil-nova-home.jpg'

import * as S from './styles';

export const compendioG = "@compendioG";


const NewHeader = ({ ...props }) => {
    const pathName = window.location.pathname

    const ShowName = () => {
        if (pathName === "/novo-produtores") {
            return null; 
        } else {
            return (
                <S.UserName>
                    <h4>Olá, Cristina Gonçalves</h4>
                </S.UserName>
            );
        }
    };
    

    return (
        <S.Container>
            <S.ContainerSubscribeAndUser>
                <S.SubscribeButton>
                    <p>ASSINAR AGORA</p>
                </S.SubscribeButton>

               {ShowName()}
            </S.ContainerSubscribeAndUser>

            <S.ContainerNotificationAndProfile>
                <S.ContainerNotification>
                    <img src={Notificação} alt="Icone de notificação"/>
                </S.ContainerNotification>

                <S.Profile>
                     <img src={Perfil} alt="Imagem de perfil"/>

                     <p>CRISTINA</p>

                     <S.ButtonProfile>
                        <img src={ArrowDown} alt="Icone de flecha direcionada para baixo"/>
                     </S.ButtonProfile>
                </S.Profile>
            </S.ContainerNotificationAndProfile>
        </S.Container>
    );
};

export default NewHeader;