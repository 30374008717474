import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";

import * as S from './styles';

import LogoBlack from '../../../assets/logo-agroreceita-black.svg'
import Celebration from '../../../assets/undraw_celebration.svg'

export const compendioG = "@compendioG";

const Apresentacao = ({ history }) => {
    const handleStart = () => {
        // localStorage.setItem('startTour', 'true');
        history.push('/');
    };

    const [emailUser, setEmailUser] = useState(" ");
    const user = JSON.parse(localStorage.getItem('@userinfo'));
    const company = user.username;
    const myArray = company.split(" ")
    
    useEffect(() => {
        setEmailUser(`${myArray.join('')}`);
    }, [myArray]); 

    return (
        <S.Container>
            <S.Wrapper>
                <img src={LogoBlack} alt="" className="logo" />

                <S.Content className="content">
                    <img src={Celebration} alt="" />

                    <h1>Bem-vindo(a) ao AgroReceita!</h1>

                    <S.Description>
                        <p>Aproveite todas as funções do <span>AgroReceita PRO</span> e melhore sua tomada de decisões no campo. </p>
                        <p>Nada será enviado às Defesas Agropecuárias durante o período de teste grátis. Explore tudo sem medo.</p>
                    </S.Description>

                    
                    <S.Button className="button" onClick={handleStart}>Começar</S.Button>
                    

                    <span>{emailUser}</span>
                </S.Content>
            </S.Wrapper>
        </S.Container>
    );
};

export default withRouter(Apresentacao);
