import local from '../../../assets/icons/icon-location.svg'
import farmer from '../../../assets/icons/default-image.png'
import whatsapp from '../../../assets/icons/whatsapp.png'
import email from '../../../assets/icons/email.png'
import phone from '../../../assets/icons/phone.png'

const events = [
    {
      id: 1,
      title: 'Análise de solo',
      date: '1 FEB 2024',
      src: local,
      location: 'FAZENDA TESTE',
      crop: 'SOJA',
      farmerImg: farmer,
    },
    {
      id: 2,
      title: 'Data plantio',
      date: '10 FEB 2024',
      src: local,
      location: 'FAZENDA TESTE',
      crop: 'SOJA',
      farmerImg: farmer,
    },
    {
      id: 3,
      title: 'Monitoramento de pragas',
      date: '15 MAR 2024',
      src: local,
      location: 'FAZENDA TESTE',
      crop: 'MILHO',
      farmerImg: farmer, 
    },
    {
        id: 4,
        title: 'Monitoramento de pragas',
        date: '15 MAR 2024',
        src: local,
        location: 'FAZENDA TESTE',
        crop: 'MILHO',
        farmerImg: farmer, 
    },    
    {
        id: 5,
        title: 'Monitoramento de pragas',
        date: '15 MAR 2024',
        src: local,
        location: 'FAZENDA TESTE',
        crop: 'MILHO',
        farmerImg: farmer, 
    },
    
  ];

  const links = [
    {
      id: 1,
      src: whatsapp,
      link: '#'
    },

    {
      id: 2,
      src: email,
      link: '#'
    },

    {
      id: 3,
      src: phone,
      link: '#'
    },

  ];

export default { events, links };