import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";

import * as S from './styles';
import NewHeader from '../../components/nova-home/header';
import MainImage from '../../assets/image-garota-na-ponte.svg';
import { buttonData } from './ButtonData.js';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import IconTooltip from '../../assets/icons/icons-tooltip.svg'


const Indique = () => {
    const [coupon, setCoupon] = useState(" ");

    const user = JSON.parse(localStorage.getItem('@userinfo'));

    const company = user.contrato.company.nome_fantasia;

    const myArray = company.split(" ")

    useEffect(() => {
        setCoupon(`AMIGO@${myArray.join('')}`.toUpperCase());
    }, [myArray]);

    const tooltipContent = (
        <div style={{ textAlign: 'left', padding: '10px', maxWidth: '300px' }}>
            <ul style={{ margin: 0, padding: '0 0 0 20px', listStyleType: 'disc' }}>
                <li>
                    <strong>Assinantes Mensal (Basic, Light ou Pro):</strong> Ganha uma mensalidade grátis para cada licença vendida com seu cupom.
                </li>
                <li>
                    <strong>Assinantes Anual (Basic, Light ou Pro):</strong> Ganha 30 dias a mais no vencimento do seu plano para cada licença vendida com seu cupom.
                </li>
                <li>
                    <strong>Não assinantes:</strong> Ganha R$ 60 de créditos pré-pago para cada licença vendida com seu cupom.
                </li>
            </ul>
        </div>
    );

    return (
        <S.Container>
            <S.Wrapper>
                <NewHeader />

                <S.ContainerCupom>
                    <S.MainImage><img src={MainImage} alt="Main" /></S.MainImage>
                    <S.CupomTitle>
                        <h1>Agora você faz parte do Programa Indique & Ganhe AgroReceita!</h1>
                    </S.CupomTitle>
                    <S.CupomText>
                        <p>
                            Você já pode começar a compartilhar seu <strong>cupom exclusivo</strong> e ganhar <strong>benefícios incríveis.</strong> Vamos juntos fazer o agronegócio crescer ainda mais!
                        </p>
                    </S.CupomText>

                    <S.ViewCupom>
                        <S.CupomLabel>Seu cupom exclusivo é</S.CupomLabel>
                        <S.Cupom>{coupon}</S.Cupom>

                    </S.ViewCupom>
                </S.ContainerCupom>

                <S.CupomSecondSection>
                    <S.Curiosity>
                        <p>
                            Sabia que o M.A.P.A. atualiza cerca de 70 bulas de defensivos por semana? AgroReceita mantém tudo sempre atualizado de acordo com as legislações de cada estado brasileiro para você emitir Receituários com segurança. Use meu cupom <span>{coupon}</span> e garanta 20% de desconto! 😉
                        </p>

                        <S.ButtonSection>
                            {buttonData.map((data) => (
                                <button key={data.id} onClick={() => data.onClick(coupon)}>
                                    <img src={data.src} alt={data.alt} />
                                    {data.text}
                                </button>
                            ))}
                        </S.ButtonSection>

                    </S.Curiosity>

                    <S.Benefits>
                        <h1>Compartilhe agora mesmo e comece a ganhar benefícios! Quanto mais você indica, mais você ganha!</h1>

                        <S.TableWrapper>
                            <S.LineInfo>
                                <p>Seu indicado <br /> compra</p>

                                <p>Seu indicado ganha</p>

                                <p>Você ganha</p>
                            </S.LineInfo>

                            <S.LineDescription>
                                <p>Qualquer plano usando seu cupom</p>

                                <p>20% de desconto</p>

                                <S.DescriptionandTooltip>
                                    <p>1 mensalidade grátis ou <br /> R$ 60 em créditos</p>

                                    <div>
                                        <Tippy
                                            content={tooltipContent}
                                            animation="fade"
                                            delay={[100, 100]}
                                            arrow={true}
                                            theme="light"
                                            placement="bottom"
                                        >

                                            <img src={IconTooltip} alt="Tooltip Icon" />
                                        </Tippy>
                                    </div>
                                </S.DescriptionandTooltip>
                            </S.LineDescription>
                        </S.TableWrapper>

                        <p>Tem alguma dúvida ou precisa de ajuda? Nossa equipe está aqui para ajudar você a aproveitar ao máximo o nosso programa. <a href='https://wa.link/e7cryc' target='blank_'>Entre em contato</a> conosco a qualquer momento!</p>
                    </S.Benefits>
                </S.CupomSecondSection>
            </S.Wrapper>
        </S.Container >
    );
};

export default withRouter(Indique);