import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 1rem;
    max-width: 20.625rem;
    max-height: 20.688rem;
    
    overflow-y: scroll;
    border-radius: 0.75rem;
    background-color: #f8f8f9;
    border: 0.3rem solid #f8f8f9;

    ::-webkit-scrollbar{
      display: none;
    }

    @media (max-width: 1319px) {
        max-width: 42.75rem;
    }

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    
    justify-content: space-between;
`;

export const Title = styled.h3`
    color: #333;
    font-size: 1rem;
    font-weight: bold;
`;

export const ViewAll = styled.a`
    gap: 0.2rem;
    display: flex;
    text-align: end;
    align-items: center;
    flex-direction: row;
    
    color: #589bff;
    cursor: pointer;
    letter-spacing: 0.1rem;

    > img{
        position: flex;
        
        @media (max-width: 333px) {
            margin-left: 2.5rem;   
        }
    }

    > h1{
      display: flex;
        
      font-weight: 800;
      font-size: 0.625rem;
      
      @media (max-width: 333px) {
            font-size: 0.45rem;   
        }
    } 
`;

export const EventCard = styled.div`
    padding: 0.8rem;
    position: relative;
    
    display: flex;
    align-items: flex-start;
    
    border-radius: 0.5rem;
    margin-bottom: 0.75rem;
    background-color: white;
    justify-content: space-between;
`;

export const EventInfo = styled.div`
    gap: 0.4rem;
    display: flex;
    flex-direction: column;
`;

export const EventTitle = styled.h4`
    width: 100%;
    
    margin: 0;
    
    color: #333;
    font-weight: bold;
    font-size: 0.875rem;
 
    @media (max-width: 376px) {
        font-size: 0.7rem;    
    }
`;

export const Mark = styled.p`
    font-size: 0.875rem;
`;

export const EventLocation = styled.p`
    max-width: 7.5rem;
    padding: 0.5rem;
    
    gap: 0.2rem;
    display: flex;
    margin: 0.25rem 0;
    
    color: #589bff;
    font-weight: 800;
    font-size: 0.65rem;
    align-items: center;
    border-radius: 999px;
    background-color: #F3F3F5;
  
`;

export const EventDate = styled.span`
    color: #888;
    font-size: 0.55rem;
    letter-spacing: 0.1rem;

    @media (max-width: 376px) {
        font-size: 0.45rem;    
    }
`;

export const TypeToxic = styled.p`
    font-weight: 700;
    font-size: 0.563rem;
    letter-spacing: 0.1rem;

    @media (max-width: 376px) {
        font-size: 0.45rem;      
    }
`;

export const Active = styled.p`
    color: #8A92A2;
    font-size: 0.563rem;
    letter-spacing: 0.1rem;

    @media (max-width: 376px) {
        font-size: 0.45rem;
        text-align: end;     
    }
`;

export const FarmWrapper = styled.div`
    height: 100%;
    
    gap: 2.1rem;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
`;
