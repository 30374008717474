import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    max-width: 20.625rem;
    justify-content: center;

    padding: 1.5rem;
    border-radius: 1rem;

    background-color: #F8F8F9;

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

export const Wrapper = styled.div`
    display: flex;

    flex-direction: column;
`;

export const ProfileCircle = styled.div`
    width: auto;
    height: auto;

    gap: 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const Profile = styled.div`
    width: auto;
    height: auto;
    padding: 0.5rem 0.5rem 0.5rem 0.25rem;

    gap: 0.5rem;
    display: flex;
    margin-top: 0.4rem;
    align-items: center;

    border-radius: 10rem;
    background-color: #F3F3F5;

    > img {
        width: 2rem;
        height: 2rem;

        border-radius: 10rem;
    }

    > p {
        color: #21262F;
        font-weight: 800;
        font-size: 0.5625rem;
        letter-spacing: 0.1rem;
        line-height: 0.84375rem;
        font-family: "Lato", Sans-serif;
    }
`;

export const ButtonProfile = styled.button`
    all: unset;

    display: flex;
    align-items: center;

    cursor: pointer;
`;

export const CirclesContainer = styled.div`
    display: flex;
    margin-bottom: 2rem;

    @media (max-width: 400px) {
        justify-content: center;
        flex-direction: column;
    }
`;

export const CircleData = styled.div`
    flex-direction: column;
    
    @media (max-width: 400px) {
        display: flex;
        align-items: center;
    }
`;

export const CircleTRT = styled.div`
    flex-direction: column;

    @media (max-width: 400px) {
        display: flex;
        align-items: center;
    }
`;

export const Subtitles = styled.p`
    text-align: center;

    color: #21262F;
    font-weight: 500;
    font-size: 0.6rem;
    letter-spacing: 0.1rem;
`;