import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;
    max-width: 64.875rem;

    display: flex;
`;

export const Header = styled.div`
    width: 100%;

    display: flex;
    padding: 1.5rem 1.625rem;
    border-radius: 1.5rem;
    align-items: center;
    justify-content: space-between;    

    background-color: #2FBB69;

    @media (max-width: 980px) {
        gap: 1.5rem;
        flex-direction: column;
    }
`;

export const HeaderTitle = styled.h5`
    color: white;
    font-size: 1.5rem;
    font-weight: 800;

    @media (max-width: 612px) {
        font-size: 1rem;
        text-align: center;
    }
`;

export const ButtonHeader = styled.button`
    all:unset;
    display: flex;
    width: 11.625rem;
    
    padding: 1rem 2.5rem;

    color: white;
    font-weight: 800;
    font-size: 0.6rem;
    align-items: center;
    border-radius: 62rem;
    letter-spacing: 0.1rem;
    background-color: #21262F;

    img{
        width: 1.3rem;
        
        margin-right: 1rem;

        @media (max-width: 612px) {
            width: 1rem;
            
            margin-right: 0.4rem;
        }
    }

    @media (max-width: 612px) {
        width: 9.125rem;
        font-size: 0.5rem;
    }

    :hover{
        cursor: pointer;
        transition: 0.5s;
        background-color: #06B04D;
    }

    :not(:hover){
        transition: 0.3s;
    }
`;