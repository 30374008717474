import React from "react";
import * as S from "./styles";

import CompendioImage from "../../../assets/compendiocartoon.svg";
import SearchIcon from "../../../assets/icons/search-icon.png";

const CompendioSearch = () => {
  return (
    <S.Container>
      <S.Wrapper>
        <S.Title>Compêndio agrícola</S.Title>

        <S.Image src={CompendioImage} alt="Compêndio" />

        <S.Description>
          Consulte os produtos agroquímicos registrados no Brasil
        </S.Description>

        <S.Button>
          <S.Icon src={SearchIcon} alt="Ícone de consulta" />
          <S.ButtonText>CONSULTAR COMPÊNDIO</S.ButtonText>
        </S.Button>
      </S.Wrapper>
    </S.Container>
  );
};

export default CompendioSearch;
