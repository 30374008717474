const events = [
    {
      id: 1,
      title: 'Abadin 72 EC',
      date: '1 FEB 2024',
      type: 'FUNGICIDA',
      active: 'PRINCÍPIO ATIVO AQUI',
      mark: 'Bayer',
    },
    {
      id: 2,
      title: 'Triclomax',
      date: '10 FEB 2024',
      type: 'FUNGICIDA',
      active: 'PRINCÍPIO ATIVO AQUI',
      mark: 'Bayer',
    },
    {
      id: 3,
      title: 'Banjo',
      date: '15 MAR 2024',
      type: 'FUNGICIDA',
      active: 'PRINCÍPIO ATIVO AQUI',
      mark: 'Bayer',
    },
    {
        id: 4,
        title: 'Banjo',
        date: '15 MAR 2024',
        type: 'FUNGICIDA',
        active: 'PRINCÍPIO ATIVO AQUI',
        mark: 'Bayer',
    },    
    {
        id: 5,
        title: 'Banjo',
        date: '15 MAR 2024',
        type: 'FUNGICIDA',
        active: 'PRINCÍPIO ATIVO AQUI',
        mark: 'Bayer',
    },
    
  ];

export default events;