const events = [
    {
      id: 1,
      title: 'Medida cautelar do Ibama suspende aplicação de defensivos à base de fipronil.',
      date: '1 FEB 2024',
      text: 'A medida cautelar do Ibama, publicada no dia 29 de dezembro de 2023, visa suspender a aplicação de defensivos à base de fipronil por meio de pulverização foliar em área total',
      read: true,
    },
    {
      id: 2,
      title: 'Medida cautelar do Ibama suspende aplicação de defensivos à base de fipronil.',
      date: '10 FEB 2024',
      text: 'A medida cautelar do Ibama, publicada no dia 29 de dezembro de 2023, visa suspender a aplicação de defensivos à base de fipronil por meio de pulverização foliar em área total',
      read: false,
    },
    {
      id: 3,
      title: 'Medida cautelar do Ibama suspende aplicação de defensivos à base de fipronil.',
      date: '17 JUN 2024',
      text: 'A medida cautelar do Ibama, publicada no dia 29 de dezembro de 2023, visa suspender a aplicação de defensivos à base de fipronil por meio de pulverização foliar em área total',
      read: true,
    },
    {
        id: 4,
        title: 'Medida cautelar do Ibama suspende aplicação de defensivos à base de fipronil.',
        date: '15 MAR 2024',
        text: 'A medida cautelar do Ibama, publicada no dia 29 de dezembro de 2023, visa suspender a aplicação de defensivos à base de fipronil por meio de pulverização foliar em área total',
        read: true,
    },    
    {
        id: 5,
        title: 'Medida cautelar do Ibama suspende aplicação de defensivos à base de fipronil.',
        date: '15 MAR 2024',
        text: 'A medida cautelar do Ibama, publicada no dia 29 de dezembro de 2023, visa suspender a aplicação de defensivos à base de fipronil por meio de pulverização foliar em área total',
        read: false,
    },
    
  ];

export default events;