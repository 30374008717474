export const ProductorsData = [
    {
        id: 1,
        productor_name: 'Pedro de Oliveira',
        local_name: 'Fazenda Estrela',
        notification: true,
        culture: [
            {
             id: 1,
             culture: 'Café',
            },
            {
                id: 2,
                culture: 'Milho',
            }
        ],
        updateDate: 'Atualizado 05/dez/2023, 13:07'
    },
    {
        id: 2,
        productor_name: 'João Bonifácio',
        local_name: 'Sítio Terra Nova',
        notification: false,
        culture: [
            {
                id: 1,
                culture: 'Soja',
            },
            {
                id: 2,
                culture: 'Milho',
            },
            {
                id: 3,
                culture: 'Tomate',
            }
        ],
        updateDate: 'Atualizado 05/dez/2023, 12:07'
    },
    {
        id: 3,
        productor_name: 'Mariana Pereira',
        local_name: 'Fazenda Catarina',
        notification: true,
        culture: [
            {
             id: 1,
             culture: 'Café',
            },
            {
                id: 2,
                culture: 'Cebola',
            }
        ],
        updateDate: 'Atualizado 05/dez/2023, 11:07'
    },
    {
        id: 4,
        productor_name: 'José da Silva',
        local_name: 'Chácara do José',
        notification: false,
        culture: [
            {
                id: 1,
                culture: 'Milho',
            }
        ],
        updateDate: 'Atualizado 04/dez/2023, 19:07'
    },
    {
        id: 5,
        productor_name: 'Pedro Ortiz',
        local_name: 'Fazenda Ortiz',
        notification: true,
        culture: [
            {
             id: 1,
             culture: 'Café',
            },
            {
                id: 2,
                culture: 'Arroz',
            }
        ],
        updateDate: 'Atualizado 03/dez/2023, 13:07'
    }
]