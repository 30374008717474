import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 0.75rem;
    max-width: 20.625rem;
    max-height: 19.188rem;
    
    overflow-y: scroll;
    border-radius: 0.75rem;
    background-color: #f8f8f9;
    border: 0.3rem solid #f8f8f9;

    ::-webkit-scrollbar{
      display: none;
    }

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;
`;

export const Title = styled.h3`
    color: #333;
    font-size: 1rem;
    font-weight: bold;
`;

export const ViewAll = styled.a`
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    color: #589bff;
    cursor: pointer;

    > img {
      display: flex;
    }

    > h1 {
      display: flex;
      
      font-weight: 800;
      font-size: 0.6rem;
      letter-spacing: 0.1rem;
    }
`;

export const EventCard = styled.div`
    width: 18.625rem;
    height: 11.875rem;
    padding: 0.5rem 0.8rem;
    
    display: flex;
    margin-bottom: 0.75rem;
    align-items: flex-start;
   
    border-radius: 0.5rem;
    background-color: white;

    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
    }
`;

export const EventInfo = styled.div`
    gap: 0.4rem;
    display: flex;
    flex-direction: column;

    line-height: 1.313rem;
`;

export const EventHeader = styled.div`
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
`;

export const EventNotificator = styled.div`
    >p {
        font-size: 1.3rem;
        color: ${props => (props.isRead ? "#589BFF": "white")};
    }
`;

export const EventTitle = styled.h4`
    width: 100%;
    height: 3.25rem;
    
    margin: 0;
    display: -webkit-box;
    
    color: #333;
    font-size: 1rem;
    overflow: hidden;
    font-weight: bold;
    line-height: 1.563rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const EventDate = styled.span`
    color: #888;
    font-size: 0.55rem;
    letter-spacing: 0.1rem;
`;

export const EventText = styled.p`
    font-size: 0.875rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
`;