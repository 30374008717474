import React, { useState } from "react";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import ArrowDown from "../../../assets/icons/icon-arrow-down-nova-home.svg";
import TemperatureMin from "../../../assets/icons/icon-calendary-weather-forecast-min.svg";
import TemperatureMax from "../../../assets/icons/icon-calendary-weather-forecast-max.svg";
import Cloud from "../../../assets/icons/icon-calendary-weather-forecast-cloud.svg";
import Precipitation from "../../../assets/icons/icon-calendary-weather-forecast-precipitation.svg";
import Umity from "../../../assets/icons/icon-calendary-weather-forecast-umity.svg";
import Air from "../../../assets/icons/icon-calendary-weather-forecast-air.svg";

import * as S from "./styles";

const CalendaryWeatherForecast = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

const getWeekRange = (date) => {
  const startOfWeek = new Date(date);
  startOfWeek.setDate(date.getDate() - date.getDay());
  startOfWeek.setHours(0, 0, 0, 0);

  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  endOfWeek.setHours(23, 59, 59, 999);

  return { startOfWeek, endOfWeek };
};

const { startOfWeek, endOfWeek } = getWeekRange(currentDate);

const tileDisabled = ({ date }) => {
  const normalizedDate = new Date(date);
  normalizedDate.setHours(0, 0, 0, 0);
  return normalizedDate < startOfWeek || normalizedDate > endOfWeek;
};

const tileClassName = ({ date, view }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const normalizedDate = new Date(date);
  normalizedDate.setHours(0, 0, 0, 0);

  const selectedDate = new Date(currentDate);
  selectedDate.setHours(0, 0, 0, 0);

  if (view === "month") {

    if (normalizedDate < today) {
      return "react-calendar__tile past-day";
    }

    if (normalizedDate.getTime() === today.getTime()) {
      if (normalizedDate.getTime() === selectedDate.getTime()) {
        return "react-calendar-dateNow-focus";
      }
      return "react-calendar__active--today";
    }

    if (normalizedDate.getTime() === selectedDate.getTime()) {
      return "react-calendar__tile-select";
    }
  }

  return null;
};


  return (
    <S.Container className="calendary">
      <S.FarmWrapper>
        <S.Lyrics>
          <S.FarmName>FAZENDA TESTE</S.FarmName>

          <S.FarmSubtittle>PRODUTOR TESTE</S.FarmSubtittle>
        </S.Lyrics>

        <S.FarmSelector>
          <img src={ArrowDown} alt="Icone de flecha direcionada para baixo" />
        </S.FarmSelector>
      </S.FarmWrapper>

      <Calendar
        onChange={setCurrentDate}
        value={currentDate}
        tileDisabled={tileDisabled}
        tileClassName={tileClassName}
        showNavigation={false}
        calendarType="gregory"
        formatShortWeekday={(locale, date) =>
          date
            .toLocaleDateString("pt-BR", { weekday: "short" })
            .charAt(0)
            .toUpperCase()
        }
        view="month"
        minDetail="month"
        maxDetail="month"
      />

      <S.Preview>
        <S.Preview1>
          <S.MinTemperature>
            <img src={TemperatureMin} alt="Ícone de temperatura mínima" />
           
            <p>Mín: 17ºC</p>
          </S.MinTemperature>

          <S.ProbabilityRain>
            <img src={Cloud} alt="Ícone de nuvem com chuva" />
           
            <p>Prob. Chuva: 3%</p>
          </S.ProbabilityRain>

          <S.Humidity>
            <img src={Umity} alt="Ícone de nuvem com gotas" />
           
            <p>Umidade: 83%</p>
          </S.Humidity>
        </S.Preview1>

        <S.Preview2>
          <S.MaxTemperature>
            <img src={TemperatureMax} alt="Ícone de temperatura máxima" />
           
            <p>Máx: 30ºC</p>
          </S.MaxTemperature>

          <S.PrecipitationRain>
            <img src={Precipitation} alt="Ícone de gota" />
           
            <p>Precipitação: 0 mm</p>
          </S.PrecipitationRain>

          <S.Wind>
            <img src={Air} alt="Ícone de vento" />
           
            <p>Vento: 22 km/h</p>
          </S.Wind>
        </S.Preview2>
      </S.Preview>
    </S.Container>
  );
};

export default CalendaryWeatherForecast;
