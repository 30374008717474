import styled from "styled-components";

export const Container = styled.div`
     width: 100%;
     height: auto;

     display: flex;
     align-items: center;
`;

export const Wrapper = styled.div` 
     width: 100%;
     height: auto;
     padding: 0 1rem 2rem 1rem;
     
     gap: 2rem;
     display: flex;
     align-items: center;
     flex-direction: column;

     background-color: #ffff;
`;

export const ContainerCupom = styled.div`
     gap: 2rem;
     display:flex;
     align-items: center;
     flex-direction: column;
`;

export const CupomSecondSection = styled.div`
     gap: 1.5rem;
     display: flex;
     margin-top: 1rem;
     flex-direction: row;

     @media (max-width: 1178px) {
          align-items: center;
          flex-direction: column;
     }
`;

export const MainImage = styled.div`
          img {
          width: 100%;
          height: 7.5rem;
          max-height: 100%;
          max-width: 8.68rem;
     }
`;

export const CupomTitle = styled.div`
     max-width: 40rem;
          
     text-align: center;

     h1 {
          font-size: 2.25rem;
          font-weight: 800;
     }
`;

export const CupomText = styled.div`
     max-width: 43.75rem;

     text-align: center;

     p {
          font-size: 1.125rem;
          font-weight: 400;
     }
`;

export const ViewCupom = styled.div`   
     padding: 1rem 1.5rem 1.25rem 1.5rem;
     
     display: flex;
     align-items: center;
     flex-direction: column;
     
     border-radius: 1rem;
     background-color: #F3F3F5;
     
`;

export const CupomLabel = styled.p`
     font-weight: 400;
     font-size: 0.75rem;
`;

export const Cupom = styled.h1`
     font-weight: 800;
     font-size: 1.5rem;
     text-transform: uppercase;
`;

export const Curiosity = styled.div`
     padding: 1.5rem;
     max-width: 26.375rem;
     
     text-align: center;
     border-radius: 1rem;
     background-color: #21262F;

     p {
          color: #FFFF;
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 1.8rem;
          
          span {
               text-transform: uppercase;
          }
     }

     @media (max-width: 386px) {
          padding: 1rem;

          p{
               font-size: 0.85rem;
          }
     }
`;

export const ButtonSection = styled.div`
     gap: 0.5rem;
     display: flex;
     margin-top: 1rem;
     flex-direction: row;

     button {
          all: unset;
          width: 100%;
          padding: 0.8rem;
          
          gap: 0.5rem;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;

          color: white;
          font-weight: 800;
          font-size: 0.625rem;
          border-radius: 40rem;
          letter-spacing: 0.1rem;
          background-color: #2FBB69;
     }

     @media (max-width: 386px) {
          gap: 0.3rem;

          button {
               font-size: 0.5rem;
               padding: 0.7rem;
          }
     }
`;

export const Benefits = styled.div`
     gap: 1rem;
     max-width: 37rem;

     h1 {
          color: #21262F;
          font-size: 1.125rem;
          margin-bottom: 1rem;
     }

     p {
          font-weight: 400;
          font-size: 0.75rem;
     }
`;

export const TableWrapper = styled.div`
     display: flex;
     margin-bottom: 1rem;
     flex-direction: column;

     border-radius: 1rem;
     border: 0.4rem solid #F8F8F9;
`;

export const LineInfo = styled.div`
     width: 100%;
     height: auto;
     padding: 1.5rem 0;
     
     display: flex;

     >p{
          display: flex;
          align-items: center;
          justify-content: center;

          font-weight: 700;
          text-align: center;
          font-family: "Lato";
          font-size: 0.875rem;
          line-height: 1.3125rem;
     }

     >p:nth-of-type(1){
          width: 100%;
          max-width: 10rem;
     }

     >p:nth-of-type(2){
          width: 100%;
          max-width: 13.5rem;
     }

     >p:nth-of-type(3){
          width: 100%;
          max-width: 13.5rem;
     }
`;

export const LineDescription = styled.div`
     width: 100%;
     height: auto;
     padding: 1rem 0;

     display: flex;

     background-color: #F8F8F9;

     >p{
          display: flex;
          align-items: center;
          justify-content: center;

          font-weight: 400;
          text-align: center;
          font-family: "Lato";
          font-size: 0.875rem;
          line-height: 1.3125rem;
     }

     >p:nth-of-type(1){
          width: 100%;
          max-width: 10rem;
     }

     >p:nth-of-type(2){
          width: 100%;
          max-width: 13.5rem;
     }
`;

export const DescriptionandTooltip = styled.div`
     width: 100%;
     height: auto;

     gap: 0.5rem;
     display: flex;
     align-items: center;
     flex-direction: column;

     >p{
          display: flex;
          align-items: center;
          justify-content: center;

          font-weight: 400;
          text-align: center;
          font-family: "Lato";
          font-size: 0.875rem;
          line-height: 1.3125rem;
     }
`;