import React from "react";
import { PieChart, Pie, Cell, Label } from "recharts";

const COLORS = ["#2FBB69", "#FFC107", "#FF3D00"];

const GraphicCircle = ({ value, label }) => {
    const maxValue = 30;
    const normalizedValue = value > maxValue ? maxValue : value;

    const data = [
        { value: normalizedValue },
        { value: maxValue - normalizedValue }
    ];

    const color = normalizedValue >= 10 ? COLORS[0] : (normalizedValue < 10 && normalizedValue >= 5 )? COLORS[1] : COLORS[2];

    return (
        <div style={{ textAlign: "center", width: "150px", height: "150px" }}>
            <PieChart width={150} height={150}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    innerRadius={50}
                    outerRadius={55}
                    dataKey="value"
                    startAngle={450}  // Começa no lado esquerdo (anti-horário)
                    endAngle={90}
                >
                    <Cell key="value" fill={color} />
                    <Cell key="remaining" fill="#E0E0E0" />
                    <Label
                        value={`${normalizedValue}`}
                        position="center"
                        style={{
                            fill: 'black',
                            fontSize: '2.7rem',
                            fontWeight: '900',
                        }}
                    />
                </Pie>
            </PieChart>
            <p>{label}</p>
        </div>
    );
};

export default GraphicCircle;
