import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 1rem;
    max-width: 20.625rem;
    max-height: 22.5rem;
    
    overflow-y: scroll;
    border-radius: 0.75rem;
    background-color: #f8f8f9;
    border: 0.3rem solid #f8f8f9;

    ::-webkit-scrollbar{
      display: none;
    }

    @media (max-width: 1070px) {
        height: 100%;

        max-height: 19.188rem;
    }

    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
    }
`;

export const Header = styled.div`
    display: flex;
    
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;
`;

export const Title = styled.h3`
    color: #333;
    font-size: 1rem;
    font-weight: bold;
`;

export const ViewAll = styled.a`
    display: flex;
    
    gap: 0.2rem;
    color: #589bff;
    cursor: pointer;
    flex-direction: row;
    align-items: center;

    > img{
      display: flex;
    }

    > h1{
      display: flex;
      font-size: 0.625rem;
      font-weight: 800;
      letter-spacing: 0.1rem;
    }
`;

export const CreateEventButton = styled.button`
    width: 100%;
    display: flex;
    padding: 0.75rem;
    
    border: none;
    color: #589bff;
    cursor: pointer;
    font-weight: 800;
    font-size: 0.65rem;
    align-items: center;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    letter-spacing: 0.1rem;
    justify-content: center;
    background-color: #e9f1ff;

    &:hover {
      background-color: #d8e9ff;
    }
`;

export const PlusIcon = styled.img`
    width: 1.4rem;
    
    margin-right: 0.5rem;
`;

export const EventCard = styled.div`
    display: flex;
    padding: 0.8rem;
    position: relative;
    
    border-radius: 0.5rem;
    align-items: flex-end;
    margin-bottom: 0.75rem;
    background-color: white;
    justify-content: space-between;
`;

export const EventInfo = styled.div`
    gap: 0.3rem;
    display: flex;
    flex-direction: column; 
`;

export const EventTitle = styled.h4`
    width: 100%;
    
    margin: 0;
    
    color: #333;
    font-weight: bold;
    font-size: 0.875rem;
`;

export const EventLocation = styled.p`
    max-width: 7.5rem;
    padding: 0.5rem;
    
    gap: 0.2rem;
    display: flex;
    margin: 0.25rem 0;
    
    color: #589bff;
    font-weight: 800;
    font-size: 0.65rem;
    align-items: center;
    border-radius: 999px;
    background-color: #F3F3F5;

    @media (max-width: 360px) {
        font-size: 0.47rem;
    }
  
`;

export const EventDate = styled.span`
    color: #888;
    font-size: 0.55rem;
    letter-spacing: 0.1rem;
`;

export const EventActions = styled.div`
    top: 0.35rem;
    position: relative;
    
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    
    align-items: flex-end;
`;

export const CropBadge = styled.span`
    padding: 0.4rem 0.5rem;
    
    margin-right: 0.5rem;
    
    color: white;
    font-weight: 700;
    font-size: 0.55rem;
    border-radius: 3rem;
    letter-spacing: 0.1rem;
    background-color: #333;
`;

export const FarmWrapper = styled.div`
    gap: 0.2rem;
    display: flex;
    flex-direction: row;

    @media (max-width: 360px) {
        gap: 0;
    }
`;

export const FarmerImage = styled.img`
    width: 1.5rem;
    
    object-fit: cover;
    border-radius: 50%;
`;

export const AnchorWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const AnchorIcons = styled.a`
    margin-right: 1rem;

    > img {
        width: 2rem;
    }
`;