import React, { useState } from 'react';
import * as S from './styles';
import events from './eventsData';
import bell from '../../../assets/icons/icon-bell-azul.png'


const Notifications = () => {
  const [isRead, setIsRead] = useState(false);
  
  return (
    <S.Container>
      <S.Header>
        <S.Title>Notificações</S.Title>
        <S.ViewAll><img src={bell} /><h1>VER TODAS</h1></S.ViewAll>
      </S.Header>

      {events.map((event) => (
        <S.EventCard key={event.id}>
          <S.EventInfo>
            
            <S.EventHeader>
              <S.EventDate>{event.date}</S.EventDate>
              <S.EventNotificator isRead={event.read} ><p>•</p></S.EventNotificator> 
            </S.EventHeader>
            
            <S.EventTitle>{event.title}</S.EventTitle>
            <S.EventText>{event.text}</S.EventText>
          </S.EventInfo>
        </S.EventCard>
      ))}
    </S.Container>
  );
};

export default Notifications;
