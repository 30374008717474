import React from 'react';
import * as S from './styles';
import data from './eventsData';
import plus from '../../../assets/icons/plus.png'
import list from '../../../assets/icons/list.png'

const { events, links } = data;

const CropManagement = () => {
  return (
    <S.Container className='cropManagement'>
      <S.Header>
        <S.Title>Manejo das lavouras</S.Title>
        <S.ViewAll><img src={list} /><h1>VER TODOS</h1></S.ViewAll>
      </S.Header>

      <S.CreateEventButton>
        <S.PlusIcon src={plus} /> CRIAR EVENTO
      </S.CreateEventButton>

      {events.map((event) => (
        <S.EventCard key={event.id}>
          <S.EventInfo>
            <S.EventTitle>{event.title}</S.EventTitle>
            <S.EventLocation>
              <img src={event.src} alt="Ícone de localização" />
              <p>{event.location}</p>
            </S.EventLocation>
            <S.EventDate>{event.date}</S.EventDate>
          </S.EventInfo>
          
          <S.EventActions>
            <S.CropBadge>{event.crop}</S.CropBadge>
            <S.FarmWrapper>
              {links.map((link) => (
                <S.AnchorWrapper>
                  <S.AnchorIcons key={link.id}>
                    <a href={link.link} target="_blank" rel="noopener noreferrer">
                      <img src={link.src} alt="Ícone de WhatsApp" />
                    </a>
                  </S.AnchorIcons>
                </S.AnchorWrapper>
              ))}
              <S.FarmerImage src={event.farmerImg} alt="Imagem do produtor" />
            </S.FarmWrapper>
          </S.EventActions>
        </S.EventCard>
      ))}

    </S.Container>
  );
};

export default CropManagement;
