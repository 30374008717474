import styled from "styled-components";

import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    .introjs-tooltip {
        width: 288px !important;
        padding: 16px !important;
        max-width: 304px !important;

        border-radius: 12px !important;
        background-color: #21262F!important;
        
        font-size: 14px;
        color: #FFFFFF !important;
    }

    .introjs-tooltiptext{
        padding: 0 !important;
        padding-bottom: 16px !important;

        gap: 4px;
        flex-direction: column;
        display: flex !important;

        >div {

            >h3{
                color: #FFFFFF;
                font-size: 20px;
                font-weight: 800;
                text-align: left;
                line-height: 26px;
                font-family: "Lato";
            }

            >p{
                color: #E9ECF0;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                line-height: 21px;
                font-family: "Lato";
            }
        }
    }

    .introjs-tooltip-header{
        padding: 0 !important;
    }

    .introjs-tooltipTitle {
        margin-bottom: 10px;

        font-size: 18px;
        font-weight: bold;
    }

    .introjs-nextbutton {
        width: auto;
        height: auto;
        padding: 8px 16px !important;

        border-radius: 10rem !important;
        background-color: #F3F3F5 !important;

        color: #21262F !important;
        font-size: 10px !important;
        font-weight: 800 !important;
        line-height: 15px !important;
        text-align: center !important;
        font-family: "Lato" !important;
        letter-spacing: 0.1em !important;
        text-transform: uppercase !important;

        :focus{
            box-shadow: none !important;
        }

    }

    .introjs-disabled{
        display: none !important;
    }

    .introjs-prevbutton {
        border: none !important;
        background-color: transparent !important;

        color: #FFFFFF !important;
        font-size: 10px !important;
        font-weight: 800 !important;
        line-height: 15px !important;
        text-shadow: none !important;
        text-align: center !important;
        font-family: "Lato" !important;
        letter-spacing: 0.1em !important;
        text-transform: uppercase !important;

        :focus{
            box-shadow: none !important;
        }
    }

    .introjs-skipbutton {
        width: 16px !important;
        height: 16px !important;

        cursor: pointer;
        color: #FFFFFF !important;
        font-weight: 400 !important;
    }

    .introjs-donebutton{
        border: none !important;
        background-color: #2FBB69 !important;

        color: #FFFFFF !important;
        text-shadow: none !important;

        font-size: 10px;
        font-weight: 800;
        line-height: 15px;
        text-align: center;
        font-family: "Lato";
        letter-spacing: 0.1em;
    }

    .introjs-tooltipbuttons{
        padding: 0 !important;

        
        display: flex !important;
        justify-content: flex-end !important;
        
        border: none !important;
    }

    .introjs-arrow.top {
        border-bottom-color: #21262F!important;
    }

    .introjs-arrow.bottom {
        border-top-color: #21262F!important;
    }

    .introjs-helperLayer{
        border-radius: 16px !important;
        box-shadow: rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important; 
    }

    .introjs-helperNumberLayer {
        left: 16px !important;
        bottom: 24px !important;
        position: absolute !important;
        
        padding: 0 !important;

        background-color: transparent;
    }

    .introjs-overlay {
        background-color: rgba(0, 0, 0, 0.7);
    }

    .custom-tooltip-header {
        color: red;
        background-color: #ff5722;
    }

    .custom-tooltip-cardNotification {
        left: 35px !important;
        top: 156px !important;

        .introjs-arrow{
            top: -18px !important;
            left: 20px !important;

            border: 10px solid transparent;
        }
    }

    .custom-tooltip-calendary {
        width: 328px !important;
        max-width: 360px !important;

        .introjs-arrow{
            left: 20px !important;
            bottom: -19px !important;

            border: 10px solid transparent;
        }

        .introjs-arrow.top{
            top: -18px !important;
            bottom: auto !important;
        }

        .introjs-arrow.bottom-middle{
            margin-left: 10px !important;

            border-top-color: #21262F!important;
        }

        .introjs-arrow.top-middle{
            top: -19px !important;
            bottom: auto !important;

            margin-left: 10px !important;

            border-bottom-color: #21262F!important;
        }
    }

    .custom-tooltip-circleData {
        width: 328px !important;
        max-width: 360px !important;

        .introjs-arrow{
            left: 20px !important;
            bottom: -19px !important;

            border: 10px solid transparent;
        }

        .introjs-arrow.top{
            top: -18px !important;
            bottom: auto !important;
        }

        .introjs-arrow.bottom-middle{
            margin-left: 10px !important;

            border-top-color: #21262F!important;
        }

        .introjs-arrow.top-middle{
            top: -19px !important;
            bottom: auto !important;

            margin-left: 10px !important;

            border-bottom-color: #21262F!important;
        }
    }

    .custom-tooltip-cropManagement {
        width: 328px !important;
        max-width: 360px !important;

        .introjs-arrow{
            left: 20px !important;
            bottom: -19px !important;

            border: 10px solid transparent;
        }

        .introjs-arrow.top{
            top: -18px !important;
            bottom: auto !important;
        }

        .introjs-arrow.bottom-middle{
            margin-left: 10px !important;

            border-top-color: #21262F!important;
        }

        .introjs-arrow.top-middle{
            top: -19px !important;
            bottom: auto !important;

            margin-left: 10px !important;

            border-bottom-color: #21262F!important;
        }
    }

    .custom-tooltip-treatedArea {
        width: 328px !important;
        max-width: 360px !important;

        .introjs-arrow{
            left: 20px !important;
            bottom: -19px !important;
            
            border: 10px solid transparent;
        }

        .introjs-arrow.top{
            top: -18px !important;
            bottom: auto !important;
        }
    }
`;

export const Container = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
`;

export const Wrapper = styled.div` 
    width: 100%;
    height: auto;

    gap: 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    background-color: #FFFF;

    >div{
        width: 100%;
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
`;

export const ContainerSections = styled.div`
    padding: 0 1rem 1rem;
    
    gap: 1.5rem;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const SectionOne = styled.div`
    width: 100%;
    
    gap: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 64.875rem;
    justify-content: center;
`;

export const SectionTwo = styled(SectionOne)``;

export const SectionThree = styled(SectionOne)``;