import React from 'react';

import * as S from './styles';

import { ProductorsData } from './ProductorsData';

import IconPlusWhite from '../../../assets/icons/iconAddHeader.svg'
import BlackLoupe from '../../../assets/icons/icons-loupe-black.svg'
import IconBell from '../../../assets/icons/icons-bell-productors.svg'
import ArrowDown from '../../../assets/icons/icon-arrow-down-nova-home.svg'

export const compendioG = "@compendioG";

const ListaProdutores = ({ ...props }) => {
    return (
        <S.Container>
            <S.Wrapper>
                <S.ContainerInputAndButton>
                    <S.ContainerInput>
                        <img src={BlackLoupe} alt="Ícone de lupa" />

                        <input placeholder='Nome do produtor, Fazenda, Cultura, etc.' />
                    </S.ContainerInput>

                    <S.ContainerLastUpdatesAndButton>
                        <S.LastUpdates>
                            <p>Últimas atualizações</p>

                            <button>
                                <img src={ArrowDown} alt='Icone de flecha direcionada para baixo' />
                            </button>
                        </S.LastUpdates>

                        <S.Button>
                            <img src={IconPlusWhite} alt='Ícone de mais' />

                            <p>Adicionar Produtor</p>
                        </S.Button>
                    </S.ContainerLastUpdatesAndButton>
                </S.ContainerInputAndButton>

                <S.ContainerLocalization>
                    {ProductorsData.map((item) => (
                        <S.Cardlocalization key={item.id}>
                            <S.ProductorAndLocal>
                                <S.ProductorName>
                                    <p>{item.productor_name}</p>

                                    <p>{item.local_name}</p>
                                </S.ProductorName>

                                {item.notification && (
                                    <S.ContainerImage>
                                        <img src={IconBell} alt='' />
                                    </S.ContainerImage>
                                )}
                            </S.ProductorAndLocal>

                            <S.ContainerCulture>
                                {item.culture.map((itemCulture) => (
                                    <S.CardCulture>
                                        <p>{itemCulture.culture}</p>
                                    </S.CardCulture>
                                ))}
                            </S.ContainerCulture>

                            <p>{item.updateDate}</p>
                        </S.Cardlocalization>
                    )).slice(0, 4)}

                </S.ContainerLocalization>
                
                <S.ButtonShowAll>
                    <p>Ver todos</p>

                    <img src={ArrowDown} alt='Icone de flecha direcionada para baixo' />
                </S.ButtonShowAll>
            </S.Wrapper>
        </S.Container>
    );
};

export default ListaProdutores;
