import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import introJs from 'intro.js';
import 'intro.js/introjs.css';

import * as S from './styles';
import NewHeader from '../../components/nova-home/header';
// import NewCardNotification from '../../components/nova-home/card-notification';
import CropManagement from '../../components/nova-home/crop-management';
import TreatedArea from '../../components/nova-home/treated-area';
import Recipe from '../../components/nova-home/recipe';
import CircleData from '../../components/nova-home/circle-data';
import Calendary from '../../components/nova-home/calendary';
import CalendaryEvent from '../../components/nova-home/calendary-event';
import CompendioSearch from '../../components/nova-home/compendio-agricola';
import Notifications from '../../components/nova-home/notifications';
import Updates from '../../components/nova-home/updates';
import CardNotification from '../../components/nova-home/card-notification'

export const compendioG = "@compendioG";

const NovaHome = ({ ...props }) => {
    const [width, setWidth] = useState(window.innerWidth);


    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);

        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    

    useEffect(() => {
        const startTour = localStorage.getItem('startTour');
    
        if (startTour === 'true') {
            localStorage.setItem('startTour', 'reload-needed');
            window.location.reload();
        } else if (startTour === 'reload-needed') {
            const intro = introJs();
            intro.setOptions({
                steps: [
                    // {
                    //     element: '.cardNotification',
                    //     intro: `<div>
                    //                <h3>Alertas de risco climático</h3>
                    //                <p>Antecipe suas decisões com nossos alertas. <br/> Você também receberá por e-mail e WhatsApp.</p>
                    //             </div>`,
                    //     tooltipClass: 'custom-tooltip-cardNotification',
                    // },
                    {
                        element: '.calendary',
                        intro: `<div>
                                    <h3>Previsão do tempo por Produtor</h3>
                                    <p>Veja a previsão do tempo dos seus clientes </br> rapidamente e melhore sua tomada de decisões.</p>
                                </div>`,
                        tooltipClass: 'custom-tooltip-calendary',
                        position: 'top',
                    },
                    {
                        element: '.circleData',
                        intro: `<div>
                                    <h3>Saldo de Receitas e ART ou TRT</h3>
                                    <p>Saiba seu saldo em tempo real e se organize melhor. </br> Você também receberá por e-mail e WhatsApp.</p>
                                </div>`,
                        tooltipClass: 'custom-tooltip-circleData',
                        position: 'top',
                    },
                    {
                        element: '.cropManagement',
                        intro: `<div>
                                    <h3>Acompanhe o manejo do dia</h3>
                                    <p>Veja as tarefas recomendadas para cada talhão dos </br> seus clientes e use os dados a seu favor.</p>
                                </div>`,
                        tooltipClass: 'custom-tooltip-cropManagement',
                        position: 'top',
                    },
                    {
                        element: '.treatedArea',
                        intro: `<div>
                                    <h3>Relatórios inteligentes</h3>
                                    <p>Receba relatórios com insights personalizados para </br> aumentar a produtividade de cada talhão.</p>
                                </div>`,
                        tooltipClass: 'custom-tooltip-treatedArea',
                        position: 'top',
                    },
                ],
                showStepNumbers: true,
                showBullets: false,
                scrollToElement: true,
                exitOnOverlayClick: false,
                prevLabel: 'Anterior',
                nextLabel: 'Próximo',
                doneLabel: 'Começar'
            });
    
            setTimeout(() => {
                intro.start();
                intro.goToStep(1); 
            }, 200);
    
            intro.onchange(() => {
                const prevButton = document.querySelector('.introjs-prevbutton');

                if (intro._currentStep === 0) {
                    if (prevButton) {
                        prevButton.classList.add('introjs-hidden');
                    }
                } else {
                    if (prevButton) {
                        prevButton.classList.remove('introjs-hidden');
                    }
                }
            });
            
    
            intro.oncomplete(() => {
                localStorage.setItem('startTour', 'completed');
            });
    
            intro.onexit(() => {
                localStorage.setItem('startTour', 'completed');
            });
        }
    }, []);
    

    const LayoutMobile = () => {
        if (width <= 1320) {
            return (
                <S.ContainerSections>
                    <Recipe />

                    <S.SectionOne>
                        <Calendary />

                        <CircleData />
                    </S.SectionOne>

                    <S.SectionTwo>
                        <Notifications />

                        <CropManagement />
                    </S.SectionTwo>

                    <TreatedArea />

                    <S.SectionThree>
                        <CalendaryEvent />

                        <CompendioSearch />

                        <Updates />
                    </S.SectionThree>
                </S.ContainerSections>
            );
        } else {
            return (
                <S.ContainerSections>
                    <Recipe />

                    <S.SectionOne>
                        <Calendary />

                        <CircleData />

                        <Notifications />
                    </S.SectionOne>

                    <S.SectionTwo>
                        <CropManagement />

                        <TreatedArea />
                    </S.SectionTwo>

                    <S.SectionThree>
                        <CalendaryEvent />

                        <CompendioSearch />

                        <Updates />
                    </S.SectionThree>
                </S.ContainerSections>
            );
        }
    }

    return (
        <>
            <S.GlobalStyles />

            <S.Container>
                <S.Wrapper>
                    <S.HeaderWrapper>
                        <NewHeader />

                        {/* <CardNotification /> */}
                    </S.HeaderWrapper>

                    {LayoutMobile()}
                </S.Wrapper>
            </S.Container>
        </>
    );
};

export default withRouter(NovaHome);
