import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: auto;

    gap: 1.5rem;
    display: flex;
    flex-direction: column;
`

export const Wrapper = styled.div`
    width: 100%;
    height: auto;

    gap: 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const ContainerInputAndButton = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    justify-content: space-between;
`

export const ContainerInput = styled.div`
    width: 100%;
    height: auto;
    max-width: 26.25rem;
    padding: 0.6875rem 1rem 0.875rem;

    gap: 0.5rem;
    display: flex;
    align-items: center;

    border-radius: 0.625rem;
    border: 1px solid #8A92A2;

    >img{
        width: auto;
        height: auto;

        display: flex;
        margin-top: 0.1875rem;
    }

    >input {
        all: unset;

        width: 100%;
        height: 1.375rem;

        display: flex;
        align-items: flex-start;

        ::placeholder {
            color: #8A92A2;
            font-weight: 400;
            font-size: 0.875rem;
            font-family: "Lato";
            line-height: 1.3125rem;
        }
    }
`

export const ContainerLastUpdatesAndButton = styled.div`
    width: auto;
    height: auto;

    gap: 0.875rem;
    display: flex;
`

export const LastUpdates = styled.div`
    width: 100%;
    height: 3rem;
    max-width: 12.6875rem;

    gap: 0.75rem;
    display: flex;
    align-items: center;
    
    >p{
        color: #21262F;
        font-weight: 800;
        text-align: center;
        font-family: "Lato";
        font-size: 0.625rem;
        line-height: 0.9375rem;
        letter-spacing: 0.0625em;
        text-transform: uppercase;
    }

    >button{
        all: unset;

        display: flex;
        align-items: center;

        cursor: pointer;

            >img{
                height: auto;
                width: 1.25rem;
            }
    }
`

export const Button = styled.button`
    all: unset;

    height: auto;
    width: 14.375rem;
    padding: 0.75rem 2rem;

    gap: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    border-radius: 0.625rem;
    background-color: #2FBB69;

    >img{
        height: auto;
        width: 1.25rem;
    }

    >p{
        cursor: pointer;
        
        color: #FFFFFF;
        font-weight: 800;
        text-align: center;
        font-family: "Lato";
        font-size: 0.625rem;
        line-height: 0.9375rem;
        letter-spacing: 0.0625em;
        text-transform: uppercase;
    }
`

export const ContainerLocalization = styled.div`
    width: 100%;
    height: auto;

    gap: 1rem;
    display: flex;
    
    overflow-x: auto;

    ::-webkit-scrollbar {
        height: 0.0625rem;
    }
`

export const Cardlocalization = styled.div`
    padding: 1rem;
    height: 9.125rem;
    min-width: 15.5rem;

    gap: 1rem;
    display: flex;
    flex-direction: column;

    border-radius: 1rem;
    background-color: #F3F3F5;

    >p{
        text-align: left;
        font-weight: 600;
        font-family: "Lato";
        font-size: 0.5625rem;
        line-height: 0.84375rem;
        letter-spacing: 0.0625em;
        text-transform: uppercase;
    }
`

export const ProductorAndLocal = styled.div`
    width: auto;
    height: auto;

    display: flex;
    justify-content: space-between;
`

export const ContainerImage = styled.div`
    width: 3rem;
    height: 2.5rem;

    display: flex;
    justify-content: center;
    
    >img{
        width: 1rem;
        height: auto;
    }
`

export const ProductorName = styled.div`
    width: auto;
    height: auto;

    display: flex;
    flex-direction: column;

    >p{
        color: #21262F;
        text-align: left;
        font-weight: 400;
        font-size: 0.75rem;
        font-family: "Lato";
        line-height: 1.125rem;
    }

    >p + p{
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.6rem;
    }
`

export const ContainerCulture = styled.div`
    width: auto;
    height: auto;

    gap: 0.5rem;
    display: flex;
`

export const CardCulture = styled.div`
    width: auto;
    height: auto;
    padding: 0.25rem 0.5rem;

    display: flex;

    border-radius: 10rem;
    background-color: #21262F;

    >p{
        color: #FFFFFF;
        font-weight: 800;
        text-align: right;
        font-family: "Lato";
        font-size: 0.5625rem;
        line-height: 0.84375rem;
        letter-spacing: 0.0625em;
        text-transform: uppercase;
    }
`

export const ButtonShowAll = styled.button`
    all: unset;
    height: auto;
    width: 6.6875rem;

    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    cursor: pointer;
    
    >p{
        cursor: pointer;

        color: #21262F;
        font-weight: 800;
        text-align: center;
        font-family: "Lato";
        font-size: 0.5625rem;
        line-height: 0.84375rem;
        letter-spacing: 0.0625em;
        text-transform: uppercase;
    }

    >img{
        width: 1rem;
        height: auto;
    }
`