import styled from "styled-components";

export const Container = styled.div`
  padding: 1.25rem;
  width: 20.625rem;
  height: 20.688rem;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 1rem;
  background-color: #ffff;
  border: 0.125rem solid #F8F8F9;

  @media (max-width: 768px) {
            width: 100% !important;
            max-width: 100% !important;
  }
`;

export const Wrapper = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

export const Title = styled.p`
  margin-bottom: 1.2rem;;
  
  color: #333333;
  font-weight: bold;
  font-size: 1.125rem;
`;

export const Image = styled.img`
  width: 9.542rem;
  height: 5.5rem;
  
  margin-bottom: 1.2rem;
`;

export const Description = styled.p`
  margin-bottom: 1.5rem;
  
  color: #666666;
  font-size: 0.875rem;
`;

export const Button = styled.button`
  padding: 0.75rem 2rem;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  cursor: pointer;
  border-radius: 1.563rem;
  border: 1px solid #333333;
  background-color: #ffffff;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const Icon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  
  margin-right: 0.625rem;
`;

export const ButtonText = styled.p`
  color: #333333;
  cursor: pointer;
  font-weight: 800;
  font-size: 0.625rem;
  letter-spacing: 0.1rem;
`;
