import styled from "styled-components";

export const Container = styled.div`
      width: 100%;
      padding: 2rem;
      height: 22.5rem;
      max-width: 42.75rem;

      border-radius: 1rem;
      background-color: #F8F8F9;
`;

export const Header = styled.div`
      width: 100%;
      
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: 400px) {
            gap: 0.5rem;
      }
`;

export const FarmWrapper = styled.div`
      height: 2.5rem;
      padding: 0.5rem;
      width: 11.813rem;
      
      display: flex;
      align-items: center;
      justify-content: center;
      
      border-radius: 62rem;
      border: 1px solid black;

      @media (max-width: 400px) {
            width: auto;
      }
`;


export const FarmName = styled.h1`
      display: flex;
      
      color: #333;
      font-weight: 800;
      font-size: 0.65rem;
      letter-spacing: 0.1rem;

      @media (max-width: 360px) {
            font-size: 0.55rem;
            text-align: center;
      }
`;

export const FarmSelector = styled.button`
      all: unset;
      top: 0.15rem;
      left: 0.5rem;
      position: relative;
      
      
      cursor: pointer;
      transition: 0.4s;

      > img{
            width: 1.25;
      }

      @media (max-width: 400px) {
            left: 0.1rem;
      }
`;

export const Report = styled.a`
      display: flex;
      
      gap: 0.3rem;
      align-items: center;
      
      letter-spacing: 0.1rem;

      > h1{
            font-weight: 800;
            font-size: 0.6rem;
      }

      @media (max-width: 400px) {

            > img {
                  width: 0.75rem;
            }
      }
`;

export const Body = styled.div`
      width: 100%;
      height: 15rem;
      
      gap: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      
      font-weight: 800;
      max-width: 39.75rem;
      flex-direction: column;

      > h1{
            text-align:center; 
            font-size: 1.25rem;
      }

      >img{
            margin-top: 2rem;
            width: 100%;
            height: auto;
      }
`;

export const ButtonHeader = styled.button`
      all:unset;
      max-width: 11.625rem;
      padding: 1rem 2.5rem;
      
      display: flex;
      align-items: center;

      color: white;
      cursor: pointer;
      font-weight: 800;
      font-size: 0.6rem;
      border-radius: 62rem;
      letter-spacing: 0.1rem;
      background-color: #2FBB69;

      img{
            width: 1.3rem;
            
            margin-right: 1rem;

            @media (max-width: 612px) {
                  width: 1rem;
                  
                  margin-right: 0.4rem;
            }
      }

      @media (max-width: 612px) {
            width: 9.125rem;
            
            font-size: 0.5rem;
      }
`;