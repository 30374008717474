import React from 'react';
import { withRouter } from "react-router-dom";

import NewHeader from '../../components/nova-home/header';

import * as S from './styles';
import ListaProdutores from '../../components/novo-produtores/ListaProdutores';

export const compendioG = "@compendioG";

const NovoProdutores = ({ ...props }) => {
    return (
        <S.Container>
            <S.Wrapper>
                <NewHeader />

                <S.ContentPage>
                    <ListaProdutores />
                </S.ContentPage>
            </S.Wrapper>
        </S.Container>
    );
};

export default withRouter(NovoProdutores);
