import whatsapp from "../../assets/icons/icon-Whatsapp.png";
import copy from "../../assets/icons/icon-copy.png";
import email from "../../assets/icons/icon-Email.png";

const handleCopy = (coupon) => {
    const textToCopy = `Sabia que o M.A.P.A. atualiza cerca de 70 bulas de defensivos por semana? Se manter atualizado é um desafio para Agrônomos que tem que prescrever agroquímicos. Por isso estou usando a AgroReceita para emitir meus Receituários Agronômicos, eles mantém tudo sempre atualizado de acordo com as legislações de cada estado brasileiro. \n\n Use meu cupom ${coupon} e garanta 20% de desconto! 😉`;
    navigator.clipboard.writeText(textToCopy);
};

const handleWhatsapp = (coupon) => {
    const text = encodeURIComponent(`Sabia que o M.A.P.A. atualiza cerca de 70 bulas de defensivos por semana? Se manter atualizado é um desafio para Agrônomos que tem que prescrever agroquímicos. Por isso estou usando a AgroReceita para emitir meus Receituários Agronômicos, eles mantém tudo sempre atualizado de acordo com as legislações de cada estado brasileiro. \n\n Use meu cupom *${coupon}* e garanta *20% de desconto!* 😉`);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const whatsappUrl = isMobile
        ? `https://api.whatsapp.com/send?text=${text}`
        : `https://web.whatsapp.com/send?text=${text}`;

    window.open(whatsappUrl, "_blank");
};

const handleEmail = (coupon) => {
    const subject = encodeURIComponent("Cupom exclusivo! Fique em dia com a legislação hoje!");
    const body = encodeURIComponent(`Sabia que o M.A.P.A. atualiza cerca de 70 bulas de defensivos por semana? Se manter atualizado é um desafio para Agrônomos que tem que prescrever agroquímicos. Por isso estou usando a AgroReceita para emitir meus Receituários Agronômicos, eles mantém tudo sempre atualizado de acordo com as legislações de cada estado brasileiro. \n\n Use meu cupom ${coupon} e garanta 20% de desconto! 😉`);
    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;

    window.location.href = mailtoLink;
};


export const buttonData = [
    {
        id: 1,
        src: copy,
        alt: "Ícone de copiar",
        text: "COPIAR",
        onClick: handleCopy,
    },

    {
        id: 2,
        src: whatsapp,
        alt: "Ícone do Whatsapp",
        text: "WHATSAPP",
        onClick: handleWhatsapp,
    },

    {
        id: 3,
        src: email,
        alt: "Ícone do Email",
        text: "EMAIL",
        onClick: handleEmail,
    },
];

